const defaultTheme = {
  color: {
    primary: "#548",
    secondary: "#fa7035",
    black: "#000",
    white: "#fff",
    gray: "#eee",
  },
  font: {
    primary: `'Source Sans Pro', sans-serif;`,
  },
  spacing: {
    unit: 8,
  },
  // based on default bootstrap 4 breakpoints
  breakpoint: {
    xxs: 411,
    xs: 480,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
    xxl: 1680,
  },
}

export const appTheme = defaultTheme

export type IAppTheme = typeof appTheme
