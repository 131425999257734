/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import React from "react"
import { ThemeProvider } from "emotion-theming"
import { appTheme } from "./src/theme"
import MasterLayout from "./src/components/MasterLayout"

export const wrapRootElement = ({ element }) => (
  <MasterLayout>
    <ThemeProvider theme={appTheme}>{element}</ThemeProvider>
  </MasterLayout>
)
