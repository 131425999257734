import { css } from "@emotion/core"
import { appTheme } from "./theme"
import { mq } from "./utils/themeUtils"

// define global styles here
export const globalStyles = css`
  :root {
    font-size: 0.9rem;

    ${mq.minWidth.lg} {
      font-size: 1rem;
    }
  }

  html,
  body,
  p {
    font-family: ${appTheme.font.primary};
    color: #333;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: ${appTheme.color.primary};
  }
`
