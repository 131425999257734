import { appTheme } from "../theme"

/**
 * Generator Media Query string
 *
 * @param {string} property - min-width|max-width|min-height|max-height
 * @param {number} value
 * @param {string} unit - default to "px"
 * @returns {string}
 */
const mqGenerator = (property, value, unit = "px") => {
  return `@media (${property}: ${value}${unit})`
}

export const mq = {
  minWidth: {
    xxs: mqGenerator("min-width", appTheme.breakpoint.xxs),
    xs: mqGenerator("min-width", appTheme.breakpoint.xs),
    sm: mqGenerator("min-width", appTheme.breakpoint.sm),
    md: mqGenerator("min-width", appTheme.breakpoint.md),
    lg: mqGenerator("min-width", appTheme.breakpoint.lg),
    xl: mqGenerator("min-width", appTheme.breakpoint.xl),
    xxl: mqGenerator("min-width", appTheme.breakpoint.xxl),
  },
  maxWidth: {
    xxs: mqGenerator("max-width", appTheme.breakpoint.xxs),
    xs: mqGenerator("max-width", appTheme.breakpoint.xs),
    sm: mqGenerator("max-width", appTheme.breakpoint.sm),
    md: mqGenerator("max-width", appTheme.breakpoint.md),
    lg: mqGenerator("max-width", appTheme.breakpoint.lg),
    xl: mqGenerator("max-width", appTheme.breakpoint.xl),
    xxl: mqGenerator("max-width", appTheme.breakpoint.xxl),
  },
}
