/** @jsx jsx */
import { jsx } from "@emotion/core"
import { FC, Fragment } from "react"
import { Global } from "@emotion/core"
import { globalStyles } from "../styles"

const MasterLayout: FC = ({ children }) => (
  <Fragment>
    <Global styles={globalStyles} />
    {children}
  </Fragment>
)

export default MasterLayout
